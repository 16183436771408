import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

const Comp = () => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  let location = useLocation();

  const [nome, setNome] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [citta, setCitta] = useState("");
  const [cap, setCap] = useState("");
  const [provincia, setProvincia] = useState("");
  // const [stato, setStato] = useState("");

  async function queryAzienda() {
    const res = await monFetch("/companyGet", {
      id: params.azienda,
    });
    console.log(res);
    const azienda = res.company;
    setNome(azienda.name);
    setIndirizzo(azienda.address);
    setCitta(azienda.city);
    setCap(azienda.zip);
    setProvincia(azienda.state);
    // setStato(azienda.country);
  }

  async function salvaAzienda(e) {
    e.preventDefault();

    // console.log(password);
    // return
    const res = await monFetch("/companySet", {
      id: params.azienda,
      name: nome,
      address: indirizzo,
      city: citta,
      zip: cap,
      state: provincia,
    });
    console.log(res);

    if (res.success) {
      window.location.reload();
    }
  }

  useEffect(() => {
    queryAzienda();
  }, []);

  return (
    <form onSubmit={(e) => salvaAzienda(e)}>
      <h2>Modifica Ditta</h2>
      <TextField
        required
        label="Nome"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={nome}
        onChange={(e) => {
          setNome(e.target.value);
        }}
      />
      <TextField
        label="Indirizzo"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={indirizzo}
        onChange={(e) => {
          setIndirizzo(e.target.value);
        }}
      />
      <TextField
        label="Citta"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={citta}
        onChange={(e) => {
          setCitta(e.target.value);
        }}
      />
      <TextField
        label="Cap"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={cap}
        inputProps={{ maxLength: 5 }}
        onChange={(e) => {
          setCap(e.target.value);
        }}
      />

      <TextField
        label="Provincia"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={provincia}
        inputProps={{ maxLength: 2 }}
        onChange={(e) => {
          setProvincia(e.target.value);
        }}
      />

      {/* <TextField
        required
        label="Stato"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={stato}
        onChange={(e) => {
          setStato(e.target.value);
        }}
      /> */}

      <Button className="inputCustom" type="submit" variant="contained">
        Salva
      </Button>
    </form>
  );
};

export default Comp;
