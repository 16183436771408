import Cookies from "js-cookie";

function Pallino(props) {
  let colore;
  if (props.attivo == 1) {
    colore = "verde";
  } else {
    colore = "rosso";
  }

  return <div className={"pallino " + colore}></div>;
}

function convertiData(input) {
  if (!input) {
    return;
  }
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(input);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

const controllaLogin = () => {
  console.log(window.location);
  if(!Cookies.get("token")){
    window.location.href="/login"
  }
  
};

const monFetch = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "monToken": Cookies.get("token"),
      "monId": Cookies.get("id"),
      "monComId": Cookies.get("companyId"),
      "monTable": Cookies.get("user_table"),
    },
    body: JSON.stringify(data),
  });
  let res = await response.json();
  if(res.monCheck == false){
    window.location.href = "/login";
  }
  //console.log(res);
  return res;
};

const monMedia = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      //   Authorization: Cookies.get("utenteId"),
    },
    body: JSON.stringify(data),
  });
  //console.log(response.headers.get('Content-Disposition'))
  let res = await response.blob();
  //console.log(res);
  return res;
};

const monForm = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
     // "Content-Type": "application/json",
      //   Authorization: Cookies.get("utenteId"),
    },
    body: data,
  });
  let res = await response.json();
  //console.log(res);
  return res;
};

const selezionaElemento = (selettore, id)=>{
  document.querySelectorAll(selettore).forEach((el) => {
    el.classList.remove("elSelezionato");
    if (el.getAttribute("data-id") == id) {
      el.classList.add("elSelezionato");
    }
  });
}

export { Pallino, convertiData, controllaLogin, monFetch, monForm, monMedia, selezionaElemento };
