import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import HomeIcon from "@mui/icons-material/Home";
import ExplicitIcon from "@mui/icons-material/Explicit";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Comp = () => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [utenti, setUtenti] = useState([]);
  const [utentiEsterni, setUtentiEsterni] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [utenteDaEliminare, setUtenteDaEliminare] = useState(null);
  const [tabellaUtente, setTabellaUtente] = useState(null);

  const [livelloAdmin, setLivelloAdmin] = useState("");
  const [optionsLivello, setOptionsLivello] = useState([]);

  const [ruolo, setRuolo] = useState("");
  const [optionsRuolo, setOptionsRuolo] = useState([]);

  // const [tipoUtente, setTipoUtente] = useState("");
  // const [optionsTipoUtente, setOptionsTipoUtente] = useState([
  //   { label: "Interni", value: "i" },
  //   { label: "Esterni", value: "e" },
  // ]);

  async function query(data = null) {
    const res = await monFetch("/userList", data);
    console.log(res);
    setUtentiEsterni(res.extUsers);
    setUtenti(res.intUsers);
  }

  async function queryLivelliAdmin() {
    const res = await monFetch("/adminLevelList", {});
    const livelliAdmin = [];
    res.level.forEach((el) => {
      livelliAdmin.push({ label: el.level, value: el.id });
    });
    console.log(livelliAdmin);
    setOptionsLivello(livelliAdmin);
  }

  async function queryRuoli() {
    const res = await monFetch("/roleList", {});
    console.log(res);
    const ruoli = [];
    res.role.forEach((el) => {
      ruoli.push({ label: el.name, value: el.id });
    });
    setOptionsRuolo(ruoli);
  }

  // async function eliminaUtente(id, tabella) {
  //   const res = await monFetch("/userDelete", { id, user_table: tabella });
  //   console.log(res);
  //   setPopupElimina(false);
  //   query();
  // }

  useEffect(() => {
    queryLivelliAdmin();
    queryRuoli();
  }, []);

  useEffect(() => {
    query({ admin_level: livelloAdmin, role: ruolo });
  }, [livelloAdmin, ruolo]);

  return (
    <>
      {Cookies.get("adminLevel") >= 2 && (
        <Button variant="contained" href="/utente-nuovo">
          Aggiungi Utente Esterno{" "}
        </Button>
      )}

      <br />
      <br />

      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          {/* livelli admin */}
          <Autocomplete
            value={
              livelloAdmin !== ""
                ? optionsLivello.find((op) => op.value == livelloAdmin)
                : null
            }
            onChange={(e, value) => {
              console.log("change autoc", value);
              if (!value) {
                setLivelloAdmin("");
                return;
              }
              setLivelloAdmin(value.value);
            }}
            options={optionsLivello}
            renderInput={(params) => <TextField {...params} label="Livello" />}
          />
        </Grid>
        {/* ruoli */}
        <Grid item sm={4} xs={12}>
          <Autocomplete
            value={
              ruolo !== "" ? optionsRuolo.find((op) => op.value == ruolo) : null
            }
            onChange={(e, value) => {
              console.log("change autoc", value);
              if (!value) {
                setRuolo("");
                return;
              }
              setRuolo(value.value);
            }}
            options={optionsRuolo}
            renderInput={(params) => <TextField {...params} label="Ruolo" />}
          />
        </Grid>
        {/* interni o esterni */}
        {/* <Grid item sm={4} xs={12}>
          <Autocomplete
            value={
              tipoUtente !== ""
                ? optionsTipoUtente.find((op) => op.value == tipoUtente)
                : null
            }
            onChange={(e, value) => {
              console.log("change autoc", value);
              if (!value) {
                setTipoUtente("");
                return;
              }
              setTipoUtente(value.value);
            }}
            options={optionsTipoUtente}
            renderInput={(params) => (
              <TextField {...params} label="Tipologia" />
            )}
          />
        </Grid> */}
      </Grid>

      {utentiEsterni.length > 0 && (
        <>
          <h2>Utenti Esterni</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ditta</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Cognome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Livello</TableCell>
                <TableCell>Ruolo</TableCell>
                <TableCell>Attivo</TableCell>
                {/* {Cookies.get("adminLevel") > 99 && <TableCell></TableCell>} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {utentiEsterni.map((utente) => {
                return (
                  <TableRow
                    key={utente.id}
                    className="rigaSelezionabile"
                    onClick={(e) => {
                      window.location.href = "/utente/" + utente.id;
                    }}
                  >
                    <TableCell>{utente.company_name}</TableCell>
                    <TableCell>{utente.first_name}</TableCell>
                    <TableCell>{utente.last_name}</TableCell>
                    <TableCell>{utente.email}</TableCell>

                    <TableCell>{utente.level}</TableCell>
                    <TableCell>
                      {utente.roles.map((e) => {
                        return (
                          <small key={e.id}>
                            {e.roleName}
                            <br />
                          </small>
                        );
                      })}
                    </TableCell>
                    <TableCell>
                      {utente.active ? (
                        <Button variant="contained" className="bottoneSuccess">
                          SI
                        </Button>
                      ) : (
                        <Button variant="contained" className="bottoneError">
                          NO
                        </Button>
                      )}
                    </TableCell>
                    {/* {Cookies.get("adminLevel") > 99 && (
                      <TableCell>
                        <DeleteIcon
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation();
                            setPopupElimina(true);
                            setUtenteDaEliminare(utente.id);
                          }}
                        />
                      </TableCell>
                    )} */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}

      <br />
      <br />

      {utenti.length > 0 && (
        <>
          <h2 style={{ marginBottom: "10px" }}>
            Utenti Elea{" "}
            <small style={{ fontSize: "11px", marginLeft: "10px" }}>
              Gli utenti Elea sono sincronizzati con il dominio. Aggiorna la
              pagina se non sono presenti gli ultimi utenti.
            </small>
          </h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Cognome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Livello</TableCell>
                <TableCell>Ruolo</TableCell>
                <TableCell>Attivo</TableCell>
                {/* {Cookies.get("adminLevel") >= 99 && <TableCell></TableCell>} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {utenti.map((utente) => {
                return (
                  <TableRow
                    key={utente.id}
                    className="rigaSelezionabile"
                    onClick={(e) => {
                      window.location.href = "/utente/" + utente.id;
                    }}
                  >
                    {/* <TableCell>{utente.company_name}</TableCell> */}
                    <TableCell>{utente.first_name}</TableCell>
                    <TableCell>{utente.last_name}</TableCell>
                    <TableCell>{utente.email}</TableCell>

                    <TableCell>{utente.adminLevel}</TableCell>
                    <TableCell>
                      {utente.roles.map((e) => {
                        return (
                          <small key={e.id}>
                            {e.roleName}
                            <br />
                          </small>
                        );
                      })}
                    </TableCell>
                    <TableCell>
                      {utente.active ? (
                        <Button variant="contained" className="bottoneSuccess">
                          SI
                        </Button>
                      ) : (
                        <Button variant="contained" className="bottoneError">
                          NO
                        </Button>
                      )}
                    </TableCell>
                    {/* {Cookies.get("adminLevel") > 99 && (
                      <TableCell>
                        <DeleteIcon
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation();
                            setPopupElimina(true);
                            setUtenteDaEliminare(utente.id);
                          }}
                        />
                      </TableCell>
                    )} */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}

      {/* <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaUtente(utenteDaEliminare, tabellaUtente)}
      /> */}
    </>
  );
};

// const Popup = (props) => {
//   return (
//     <Dialog open={props.open} onClose={props.fnAnnulla}>
//       <DialogTitle>Conferma Eliminazione</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           Confermi l'eliminazione dell'elemento?
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={props.fnAnnulla}>Annulla</Button>
//         <Button onClick={props.fnSuccess} variant="contained">
//           Elimina
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

export default Comp;
