import * as React from "react";
import { useState, useEffect } from "react";
import { controllaLogin } from "./inc/inc";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import Login from "./comp/Login";
import RecuperaPassword from "./comp/RecuperaPassword";
import ReimpostaPassword from "./comp/ReimpostaPassword";
import UtentiLista from "./comp/UtentiLista";
import UtentiNuovo from "./comp/UtentiNuovo";
import UtentiModifica from "./comp/UtentiModifica";

import RuoliLista from "./comp/RuoliLista";
import RuoliNuovo from "./comp/RuoliNuovo";
import RuoliModifica from "./comp/RuoliModifica";

import AziendeLista from "./comp/AziendeLista";
import AziendeNuovo from "./comp/AziendeNuovo";
import AziendeModifica from "./comp/AziendeModifica";

import AttivitaLista from "./comp/AttivitaLista";
import AttivitaNuovoAggiorna from "./comp/AttivitaNuovoAggiorna";

import Calendario from './comp/Calendario'

import Layout from "./comp/layout/Layout";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#666",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />

        {/* <Container style={{maxWidth: "100%"}}> */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="recupera-password" element={<RecuperaPassword />} />
          <Route path="reimposta-password" element={<ReimpostaPassword />} />

          <Route path="/" element={<Layout />}>
            <Route path="attivita" element={<AttivitaLista />} />
            <Route path="attivita-nuova" element={<AttivitaNuovoAggiorna />} />
            <Route path="attivita/:attivita" element={<AttivitaNuovoAggiorna />} />

            <Route path="/" element={<Calendario />} />

            <Route path="utenti" element={<UtentiLista />} />
            <Route path="utente-nuovo" element={<UtentiNuovo />} />
            <Route path="utente/:utente" element={<UtentiModifica />} />

            <Route path="ruoli" element={<RuoliLista />} />
            <Route path="ruolo-nuovo" element={<RuoliNuovo />} />
            <Route path="ruolo/:ruolo" element={<RuoliModifica />} />

            <Route path="aziende" element={<AziendeLista />} />
            <Route path="azienda-nuovo" element={<AziendeNuovo />} />
            <Route path="azienda/:azienda" element={<AziendeModifica />} />
          </Route>
        </Routes>
        {/* </Container> */}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
