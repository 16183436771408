import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

const Comp = () => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [email, setEmail] = useState("");

  const [errore, setErrore] = useState("");

  async function salvaUtente(e) {
    e.preventDefault();
    const res = await monFetch("/userCreate", {
      email: email,
    });
    console.log(res);
    if (res.success) {
      window.location.href = "/utente/" + res.user;
    } else {
      setErrore(res.msg);
    }
  }

  // useEffect(() => {
  //   query();
  // }, []);

  return (
    <form onSubmit={(e) => salvaUtente(e)}>
      <h2>Nuovo Utente</h2>
      {errore && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errore}
        </Alert>
      )}

      {/* <TextField
        label="Nome"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={nome}
        onChange={(e) => {
          setNome(e.target.value);
        }}
      />
      <TextField
        label="Cognome"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={cognome}
        onChange={(e) => {
          setCognome(e.target.value);
        }}
      /> */}
      <TextField
        label="Email (Lasciare vuota se non richiesta)"
        fullWidth
        type="email"
        variant="outlined"
        className="inputCustom"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      {/* <TextField
        label="Password"
        fullWidth
        type="password"
        variant="outlined"
        className="inputCustom"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <div className="valign inputCustom">
        <span>Attivo: </span>
        <Checkbox
          checked={attivo}
          onChange={(e) => setAttivo(e.target.checked)}
        />
      </div> */}
      <Button className="inputCustom" type="submit" variant="contained">
        Salva
      </Button>
    </form>
  );
};

export default Comp;
