import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { convertiData, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import HomeIcon from "@mui/icons-material/Home";
import ExplicitIcon from "@mui/icons-material/Explicit";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";

const Comp = () => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [tasks, setTasks] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [popupFatto, setPopupFatto] = useState(false);
  const [popupFattoOk, setPopupFattoOk] = useState(0);

  const [dataDa, setDataDa] = useState("");
  const [dataA, setDataA] = useState("");
  const [showDone, setShowDone] = useState(false);

  const [azienda, setAzienda] = useState(null);
  const [aziende, setAziende] = useState([]);

  const [montatori, setMontatori] = useState(null);
  const [montatoreId, setMontatoreId] = useState(null);
  const [montatoreTab, setMontatoreTab] = useState(null);
  const [optionsMontatori, setOptionsMontatori] = useState([]);

  const [capoCommessa, setCapoCommessa] = useState(null);
  const [capoCommessaId, setCapoCommessaId] = useState(null);
  const [capoCommessaTab, setCapoCommessaTab] = useState(null);
  const [optionsCapoCommessa, setOptionsCapoCommessa] = useState([]);

  const optionsFatto = [
    { label: "No", value: 0 },
    { label: "Si", value: 1 },
  ];

  async function query(data = null) {
    const res = await monFetch("/taskList", data);
    console.log(res);
    setTasks(res.tasks);
  }

  async function eliminaTask(id) {
    const res = await monFetch("/taskDelete", { id });
    console.log(res);
    //setPopupElimina(false);
    query();
  }

  async function queryMontatori() {
    const res = await monFetch("/montatoriGet", {});
    console.log(res);
    const temp = [];
    res.montatori.forEach((el) => {
      let label = el.first_name + " " + el.last_name;

      temp.push({ label: label, value: el.id, ext: el.username == null });
    });

    console.log(1111, temp);
    setOptionsMontatori(temp);
  }

  async function queryCapiCommessa() {
    const res = await monFetch("/capiCommessaGet", {});
    console.log(res);
    const temp = [];
    res.capiCommessa.forEach((el) => {
      temp.push({
        label: el.first_name + " " + el.last_name,
        value: el.id,
        ext: el.username == null,
      });
    });
    setOptionsCapoCommessa(temp);
  }

  async function queryAziende() {
    const res = await monFetch("/companyList", {});
    console.log(res);
    let aziende = [];
    res.company.forEach((el) => {
      aziende.push({ label: el.name, id: el.id });
    });
    console.log(aziende);
    setAziende(aziende);
  }

  async function setFatto(id, fatto) {
    const res = await monFetch("/taskDone", { id, done: fatto });
    console.log(res);
    query({ date_start: dataDa, date_end: dataA, show_done: showDone });
    setPopupFatto(false);
  }

  // useEffect(() => {
  //   query();
  // }, []);

  useEffect(() => {
    queryMontatori();
    queryCapiCommessa();
    queryAziende();
    query({
      date_start: dataDa,
      date_end: dataA,
      show_done: showDone,
      montatore_id: montatoreId,
      montatore_table: montatoreTab,
      capo_commessa_id: capoCommessaId,
      capo_commessa_table: capoCommessaTab,
      company_id: azienda?.id,
    });
  }, [dataA, dataDa, showDone, montatoreId, capoCommessaId, azienda]);

  return (
    <>
      {Cookies.get("adminLevel") > 2 && (
        <div className="flexCenter" style={{ justifyContent: "space-between" }}>
          <Button variant="contained" href="/attivita-nuova">
            Nuovo
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              var prtContent = document.getElementById("tabellaAttivita");
              var WinPrint = window.open(
                "",
                "",
                "left=0,top=0,width=900,height=700,toolbar=0,scrollbars=0,status=0"
              );
              let mill = new Date();
              mill = mill.getTime();
              WinPrint.document.write(
                '<html><head><link rel="stylesheet" href="/stile.css?d=' +
                  mill +
                  '"></head><body>'
              );
              WinPrint.document.write(prtContent.outerHTML);
              WinPrint.document.write("</body></html>");
              //WinPrint.document.close();
              WinPrint.focus();
              setTimeout(() => {
                WinPrint.print();
                WinPrint.close();
              }, 500);
            }}
          >
            Stampa / PDF
          </Button>
        </div>
      )}

      <br />
      <br />

      <Grid container spacing={2}>
        <Grid item sm={2} xs={12}>
          <TextField
            label="Data Inizio"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            variant="outlined"
            value={dataDa}
            onChange={(e) => {
              setDataDa(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <TextField
            label="Data Fine"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            variant="outlined"
            value={dataA}
            onChange={(e) => {
              setDataA(e.target.value);
            }}
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          {/* //azienda */}
          <Autocomplete
            value={azienda}
            onChange={(e, value) => {
              console.log("change autoc", value);
              if (!value) {
                setAzienda(null);
                return;
              }
              setAzienda(value);
              //setOptionsMontatoriSelezionate(opzioniSelezionate);
            }}
            options={aziende}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Azienda"
                className="inputCustom selectMontatori"
              />
            )}
          />
        </Grid>

        <Grid item sm={2} xs={12}>
          {/* //capocommessa */}
          <Autocomplete
            //multiple
            value={capoCommessa}
            onChange={(e, value) => {
              console.log("change autoc", value);
              if (!value) {
                setCapoCommessa(null);
                setCapoCommessaId(null);
                setCapoCommessaTab(null);
                return;
              }
              setCapoCommessa(value);
              setCapoCommessaTab(value.ext);
              setCapoCommessaId(value.value);
              //setOptionsMontatoriSelezionate(opzioniSelezionate);
            }}
            renderOption={(props, op) => {
              let label = null;
              if (!op.ext) {
                label = (
                  <div className="flexCenter" {...props}>
                    <HomeIcon style={{ marginRight: 10 }} /> {op.label}
                  </div>
                );
              } else {
                label = (
                  <div className="flexCenter" {...props}>
                    <ExplicitIcon style={{ marginRight: 10 }} /> {op.label}
                  </div>
                );
              }
              return label;
            }}
            options={optionsCapoCommessa}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Capo Commessa"
                className="inputCustom selectMontatori"
              />
            )}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          {/* //montatori */}
          <Autocomplete
            //multiple
            value={montatori}
            onChange={(e, value) => {
              console.log("change autoc", value);
              if (!value) {
                setMontatori(null);
                setMontatoreTab(null);
                setMontatoreId(null);
                return;
              }
              setMontatori(value);
              setMontatoreTab(value.ext);
              setMontatoreId(value.value);
              //setOptionsMontatoriSelezionate(opzioniSelezionate);
            }}
            renderOption={(props, op) => {
              let label = null;
              if (!op.ext) {
                label = (
                  <div className="flexCenter" {...props}>
                    <HomeIcon style={{ marginRight: 10 }} /> {op.label}
                  </div>
                );
              } else {
                label = (
                  <div className="flexCenter" {...props}>
                    <ExplicitIcon style={{ marginRight: 10 }} /> {op.label}
                  </div>
                );
              }
              return label;
            }}
            options={optionsMontatori}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Montatore"
                className="inputCustom selectMontatori"
              />
            )}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          <Autocomplete
            fullWidth
            options={optionsFatto}
            value={showDone ? optionsFatto[1] : optionsFatto[0]}
            renderInput={(params) => (
              <TextField {...params} label="Completati" />
            )}
            onChange={(e, value) => {
              if (value) {
                setShowDone(value.value);
              } else {
                setShowDone(0);
              }
            }}
          />
        </Grid>
      </Grid>

      <h2>Attività</h2>
      <Table id="tabellaAttivita">
        <TableHead>
          <TableRow>
            <TableCell className="fatto">Fatto</TableCell>
            <TableCell>Data Inizio</TableCell>
            <TableCell>Data Fine</TableCell>
            <TableCell>Luogo</TableCell>
            <TableCell>Attività</TableCell>
            <TableCell>Durata</TableCell>
            <TableCell>Capo Commessa</TableCell>
            <TableCell>Montatori</TableCell>
            {Cookies.get("adminLevel") > 2 && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((t) => {
            let Montatori = () => {
              return t.montatori.map((t, index) => {
                if (t) {
                  return <p key={index}>{t}</p>;
                }
              });
            };
            return (
              <TableRow
                key={t.id}
                className="rigaSelezionabile"
                onClick={(e) => {
                  window.location.href = "/attivita/" + t.id;
                }}
              >
                <TableCell className="fatto">
                  <Checkbox
                    checked={t.done ? true : false}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      let tasks2 = [...tasks];
                      let task = tasks2.find((e) => {
                        return e.id == t.id;
                      });
                      if (t.done == 0) {
                        task.done = 1;
                        setPopupFattoOk(1);
                      } else {
                        t.done = 0;
                        setPopupFattoOk(0);
                      }
                      setTasks(tasks2);

                      setPopupFatto(true);
                      setDaEliminare(t.id);
                    }}
                  />
                </TableCell>
                <TableCell>{convertiData(t.date_start)}</TableCell>
                <TableCell>{convertiData(t.date_end)}</TableCell>
                <TableCell>
                  {t.address ? (
                    <>
                      {t.company} <br /> {t.city} - {t.address}
                    </>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell>{t.task}</TableCell>
                <TableCell>{t.duration}</TableCell>
                <TableCell>{t.capoCommessa}</TableCell>
                <TableCell
                //dangerouslySetInnerHTML={{ __html: montatori }}
                >
                  <Montatori />
                </TableCell>
                {Cookies.get("adminLevel") > 2 && (
                  <TableCell>
                    <DeleteIcon
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        setPopupElimina(true);
                        setDaEliminare(t.id);
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaTask(daEliminare)}
      />
      <PopupFatto
        open={popupFatto}
        popupFattoOk={popupFattoOk}
        fnAnnulla={(e) => setPopupFatto(false)}
        fnSuccess={(e) => setFatto(daEliminare, popupFattoOk)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PopupFatto = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>
        Contrassegna come {props.popupFattoOk ? "fatto" : "non fatto"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi che l'attività {props.popupFattoOk ? "" : "non"} è stata
          svolta?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
