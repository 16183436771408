import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import GroupsIcon from '@mui/icons-material/Groups';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import { controllaLogin, monFetch } from "../../inc/inc";
import Grid from "@mui/material/Grid";

const Comp = (props) => {
  //const ruoloLogin = props.controllaLogin();
  //console.log(444, ruoloLogin);
  const [menuAperto, setMenuAperto] = useState(false);
  const [apriMenuUtente, setApriMenuUtente] = useState(false);
  const [emailUtente, setEmailUtente] = useState("");
  const [nomeCognome, setNomeCognome] = useState("");
  const [level, setLevel] = useState(null);

  let location = useLocation();

  useEffect(() => {
    //   //in file inc. redirect se non in pagina libri
    controllaLogin();
    getUtente();
  }, [location]);

  async function getUtente() {
    let res = await monFetch("/userGet", {
      id: Cookies.get("id"),
      table: Cookies.get("user_table"),
    });
    setEmailUtente(res.user.email);
    setNomeCognome(res.user.first_name + " " + res.user.last_name);
    setLevel(res.user.level);
    Cookies.set("adminLevel", res.user.admin_level_id, { expires: 7 });
  }

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("id");
    Cookies.remove("adminLevel");
    Cookies.remove("companyId");
    Cookies.remove("roles");
    Cookies.remove("user_table");
    window.location.href = "/login";
  };

  return (
    <>
      <AppBar position="static" sx={{ mb: 2 }} className="barraTop">
        <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                size="large"
                edge="start"
                sx={{ mr: 2 }}
                onClick={() => setMenuAperto(!menuAperto)}
              >
                <MenuIcon />
              </IconButton>

              <Link to="/" style={{ marginRight: "15px", display: "flex" }}>
                <img
                  src="/img/elea-logo.svg"
                  style={{ width: "80px", padding: "10px 0" }}
                />
              </Link>
            </div>
            <div style={{ display: "flex" }}>
              <Button color="inherit" onClick={logout}>
                Logout
              </Button>
              <IconButton
                className="bottoneUtente"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                open={apriMenuUtente}
                anchorEl={document.querySelector(".bottoneUtente")}
                id="menu-appbar"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => setApriMenuUtente(!apriMenuUtente)}>
                  <div>
                    {level}
                    <br />
                    <b>{nomeCognome}</b>
                    <br />
                    {emailUtente}
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        className="menuLaterale"
        anchor="left"
        open={menuAperto}
        onClose={() => setMenuAperto(!menuAperto)}
      >
        <List>
          {Cookies.get("adminLevel") > 1 && (
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/attivita"
                onClick={() => setMenuAperto(!menuAperto)}
              >
                <ListItemIcon>
                  <PlaylistAddCheckIcon />
                </ListItemIcon>
                <ListItemText primary="Attività" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/"
              onClick={() => setMenuAperto(!menuAperto)}
            >
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText primary="Calendario" />
            </ListItemButton>
          </ListItem>
          {Cookies.get("adminLevel") > 1 && (
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/utenti"
                onClick={() => setMenuAperto(!menuAperto)}
              >
                <ListItemIcon>
                <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Utenti" />
              </ListItemButton>
            </ListItem>
          )}
          {Cookies.get("adminLevel") == 99 && (
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/ruoli"
                onClick={() => setMenuAperto(!menuAperto)}
              >
                <ListItemIcon>
                  <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary="Ruoli" />
              </ListItemButton>
            </ListItem>
          )}
          {Cookies.get("adminLevel") > 2 && (
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/aziende"
                onClick={() => setMenuAperto(!menuAperto)}
              >
                <ListItemIcon>
                <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary="Ditte" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Drawer>
      <Container>
        {/* //mostra la route figlia */}
        <Outlet />
      </Container>
      {/* {props.children} */}
      <br />
      <br />
      <br />
    </>
  );
};

export default Comp;
