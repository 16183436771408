import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Comp = () => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [aziende, setAziende] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [aziendaDaEliminare, setAziendaDaEliminare] = useState(null);

  async function query() {
    const res = await monFetch("/companyList", {});
    console.log(res);
    setAziende(res.company);
  }

  async function eliminaAzienda(id) {
    const res = await monFetch("/companyDelete", { id });
    console.log(res);
    setPopupElimina(false);
    query();
  }

  useEffect(() => {
    query();
  }, []);

  return (
    <>
      <Button variant="contained" href="/azienda-nuovo">
        Nuovo{" "}
      </Button>
      <h2>Ditte</h2>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Indirizzo</TableCell>
            <TableCell>Città</TableCell>
            <TableCell>CAP</TableCell>
            <TableCell>Provincia</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aziende.map((azienda) => {
            return (
              <TableRow
                key={azienda.id}
                className="rigaSelezionabile"
                onClick={(e) => {
                  window.location.href = "/azienda/" + azienda.id;
                }}
              >
                <TableCell>{azienda.name}</TableCell>
                <TableCell>{azienda.address}</TableCell>
                <TableCell>{azienda.city}</TableCell>
                <TableCell>{azienda.zip}</TableCell>
                <TableCell>{azienda.state}</TableCell>
                <TableCell>
                  <DeleteIcon
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopupElimina(true);
                      setAziendaDaEliminare(azienda.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaAzienda(aziendaDaEliminare)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
