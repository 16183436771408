import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import HomeIcon from "@mui/icons-material/Home";
import ExplicitIcon from "@mui/icons-material/Explicit";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { duration, tabClasses, unstable_composeClasses } from "@mui/material";
import { color } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { options } from "preact";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  let location = useLocation();
  let id = null;

  if (params.attivita) {
    id = params.attivita;
  }
  if (props.id) {
    id = props.id;
  }

  //console.log("id:" + id);

  const [dataInizio, setDataInizio] = useState("");
  const [dataFine, setDataFine] = useState("");
  const [durata, setDurata] = useState("");
  const [idCapoCommessa, setIdCapoCommessa] = useState(null);
  const [tabellaCapoCommessa, setTabellaCapoCommessa] = useState(null);
  const [idLuogo, setIdLuogo] = useState(null);
  const [testoSelectLuogo, setTestoSelectLuogo] = useState("");
  const [lavoro, setLavoro] = useState("");
  const [colore, setColore] = useState("");
  const [daAssegnare, setDaAssegnare] = useState(false);
  const [montatori, setMontatori] = useState([]);
  const [montatoriExt, setMontatoriExt] = useState([]);
  const [idFurgone, setIdFurgone] = useState(1);
  const [ordineEsec, setOrdineEsec] = useState(1);

  const [isDisabilitato, setIsDisabilitato] = useState(false);

  const [optionsCapoCommessa, setOptionsCapoCommessa] = useState([]);
  const [optionsMontatori, setOptionsMontatori] = useState([]);
  const [optionsMontatoriSelezionate, setOptionsMontatoriSelezionate] =
    useState([]);
  const [optionsLuogo, setOptionsLuogo] = useState([]);
  const [optionsFurgone, setOptionsFurgone] = useState([]);

  async function queryTask() {
    const res = await monFetch("/taskGet", {
      id: id,
    });
    console.log(res);

    const task = res.task;
    const montatori = task.montatori_ids?.split(",");
    const montatoriExt = task.montatori_ids_external?.split(",");
    //console.log(montatoriExt, 11111)
    setDataInizio(task.date_start);
    setDataFine(task.date_end);
    setDurata(task.duration);
    setIdCapoCommessa(task.capo_commessa_id);
    setTabellaCapoCommessa(task.capo_commessa_table);
    setIdLuogo(task.location_id);
    setLavoro(task.task);
    setColore(task.color);
    setDaAssegnare(task.to_assign ? true : false);
    setMontatori(montatori);
    setMontatoriExt(montatoriExt);
    setOrdineEsec(task.execution_order);
    setIdFurgone(task.van_id);

    queryLuoghi("", task.location_id);
    // //ruoli utente
    // const ruoliTemp = {};
    // user.roles.forEach((el) => {
    //   ruoliTemp[el.role_id] = true;
    // });
    // setRuoliUtente(ruoliTemp);
  }

  useEffect(() => {
    ////voci montatori selezionate
    //console.log(111, optionsMontatori, montatori)
    let optionsMontatoriSelezionate = [];
    montatori?.forEach((e) => {
      optionsMontatori.forEach((e2) => {
        //console.log(e2, e)
        if (e2.value == Number(e)) {
          optionsMontatoriSelezionate.push(e2);
        }
      });
    });
    montatoriExt?.forEach((e) => {
      optionsMontatori.forEach((e2) => {
        console.log(e2, e);
        if (e2.value == Number(e) ) {
          optionsMontatoriSelezionate.push(e2);
        }
      });
    });
    //console.log(optionsMontatoriSelezionate)
    setOptionsMontatoriSelezionate(optionsMontatoriSelezionate);
  }, [optionsMontatori, montatori, montatoriExt]);

  async function queryLuoghi(testo = "", id = "") {
    const res = await monFetch("/locationList", { search: testo, id: id });
    let location = [];
    console.log(res);
    res.location.forEach((el) => {
      location.push({
        label:
          el.company +
          " | " +
          el.address +
          " " +
          el.city +
          " " +
          el.state +
          " - " +
          el.code,
        value: el.code,
      });
    });
    //cerca se il testo è una opzione esatta
    let testoEsatto = false;
    optionsLuogo.forEach((e) => {
      if (e.label == testo) {
        testoEsatto = true;
      }
    });
    if (!testoEsatto) {
      setOptionsLuogo(location);
    }
  }

  async function queryMontatori() {
    const res = await monFetch("/montatoriGet", {});
    console.log(res);
    const temp = [];
    res.montatori.forEach((el) => {
      let label = el.first_name + " " + el.last_name;

      temp.push({ label: label, value: el.id, ext: el.username == null });
    });
    setOptionsMontatori(temp);
  }

  async function queryCapiCommessa() {
    const res = await monFetch("/capiCommessaGet", {});
    console.log(res);
    const temp = [];
    res.capiCommessa.forEach((el) => {
      temp.push({
        label: el.first_name + " " + el.last_name,
        value: el.id,
        ext: el.username == null,
      });
    });
    setOptionsCapoCommessa(temp);
  }

  async function queryFurgone() {
    const res = await monFetch("/vanList", {});
    console.log(res);
    const temp = [];
    res.van.forEach((el) => {
      temp.push({ label: el.van, value: el.id });
    });
    setOptionsFurgone(temp);
  }

  async function salvaAttivita(e) {
    e.preventDefault();

    //array di id ruoli da passare
    // const ruoliUtenteSalva = [];
    // Object.entries(ruoliUtente).forEach((k) => {
    //   if (k[1] == true) {
    //     ruoliUtenteSalva.push(k[0]);
    //   }
    // });

    // console.log(password);
    // return

    const data = {
      //se è modifica passo id, altrimenti null
      id: id ? id : null,
      date_start: dataInizio,
      date_end: dataFine,
      duration: durata,
      capo_commessa_id: idCapoCommessa,
      location_id: idLuogo,
      color: colore,
      to_assign: daAssegnare,
      montatori_ids: montatori,
      montatori_ids_external: montatoriExt,
      task: lavoro,
      van_id: idFurgone,
      execution_order: ordineEsec,
      capo_commessa_table: tabellaCapoCommessa,
    };

    console.log(data);
    // if (livelloAdmin == 2) {
    //   delete data.company_id;
    // }
    const res = await monFetch("/taskSet", data);
    console.log(res);
    // return
    if (res.success) {
      if (id) {
        window.location.reload();
      } else {
        ////se nuovo
        window.location.href = "/attivita/" + res.task;
      }
    }
  }

  useEffect(() => {
    queryMontatori();
    queryCapiCommessa();
    queryFurgone();

    if (Cookies.get("adminLevel") <= 2) {
      setIsDisabilitato(true);
    }

    // queryLuoghi();
  }, []);

  useEffect(() => {
    if (id) {
      queryTask();
    }
  }, [id]);

  if (dataInizio == "" && id) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={(e) => salvaAttivita(e)}>
      {id ? <h2>Modifica Attività</h2> : <h2>Nuova Attività</h2>}
      <Grid container spacing={2}>
        <Grid item sm={3} xs={12}>
          <TextField
            disabled={isDisabilitato}
            label="Data Inizio"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            variant="outlined"
            className="inputCustom"
            value={dataInizio}
            onChange={(e) => {
              setDataInizio(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            disabled={isDisabilitato}
            label="Data Fine"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            variant="outlined"
            className="inputCustom"
            value={dataFine}
            inputProps={{
              min: dataInizio,
            }}
            onChange={(e) => {
              setDataFine(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            disabled={isDisabilitato}
            label="Durata prevista"
            //type="number"
            //inputProps={{ max: "9999" }}
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={durata || ""}
            onChange={(e) => {
              setDurata(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          <TextField
            disabled={isDisabilitato}
            label="Ordine Esecuzione"
            type="number"
            inputProps={{ max: "9999" }}
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={ordineEsec || ""}
            onChange={(e) => {
              setOrdineEsec(e.target.value);
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          {/* //capo commessa */}
          <Autocomplete
            disabled={isDisabilitato}
            value={
              optionsCapoCommessa.find((op) => op.value == idCapoCommessa)
                ? optionsCapoCommessa.find((op) => op.value == idCapoCommessa)
                : null
            }
            onChange={(e, value) => {
              console.log("change autoc", value);
              setIdCapoCommessa(value?.value);
              setTabellaCapoCommessa(value?.tabella);
            }}
            options={optionsCapoCommessa}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Capo Commessa"
                className="inputCustom"
              />
            )}
            renderOption={(props, op) => {
              let label = null;
              if (!op.ext) {
                label = (
                  <div className="flexCenter" {...props}>
                    <HomeIcon style={{ marginRight: 10 }} /> {op.label}
                  </div>
                );
              } else {
                label = (
                  <div className="flexCenter" {...props}>
                    <ExplicitIcon style={{ marginRight: 10 }} /> {op.label}
                  </div>
                );
              }
              return label;
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          {/* //furgone */}
          <Autocomplete
            disabled={isDisabilitato}
            value={
              optionsFurgone.find((op) => op.value == idFurgone)
                ? optionsFurgone.find((op) => op.value == idFurgone)
                : null
            }
            onChange={(e, value) => {
              console.log("change autoc", value);
              if (value) {
                setIdFurgone(value.value);
              }
            }}
            options={optionsFurgone}
            renderInput={(params) => (
              <TextField {...params} label="Furgone" className="inputCustom" />
            )}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          <TextField
            disabled={isDisabilitato}
            label="Colore"
            type="color"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={colore || ""}
            onChange={(e) => {
              setColore(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <span className="checkMultiple">
            <span>Da assegnare</span>
            <Checkbox
              disabled={isDisabilitato}
              checked={daAssegnare}
              onChange={() => setDaAssegnare(!daAssegnare)}
            />
          </span>
        </Grid>
        <Grid item sm={12} xs={12}>
          {/* //montatori */}
          <Autocomplete
            disabled={isDisabilitato}
            multiple
            value={optionsMontatoriSelezionate}
            onChange={(e, value) => {
              console.log("change autoc", value);
              const m = [];
              const mExt = [];
              ///const opzioniSelezionate = [];
              value.forEach((el) => {
                if (!el.ext) {
                  m.push(el.value);
                } else {
                  mExt.push(el.value);
                }
                //valori unici
                //mezzi = [... new Set(mezzi)]
                //opzioniSelezionate.push(el);
              });
              setMontatori(m);
              setMontatoriExt(mExt);

              //setOptionsMontatoriSelezionate(opzioniSelezionate);
            }}
            renderOption={(props, op) => {
              let label = null;
              if (!op.ext) {
                label = (
                  <div className="flexCenter" {...props}>
                    <HomeIcon style={{ marginRight: 10 }} /> {op.label}
                  </div>
                );
              } else {
                label = (
                  <div className="flexCenter" {...props}>
                    <ExplicitIcon style={{ marginRight: 10 }} /> {op.label}
                  </div>
                );
              }
              return label;
            }}
            // renderTags={(value, props) => {
            //   //console.log(i);
            //   let tags = [];
            //   value.forEach((v, i) => {
            //     let label = null;
            //     if (v.tabella == "i") {
            //       tags.push(
            //         <div className="flexCenter" {...props(i)}>
            //           <HomeIcon style={{ marginRight: 10 }} /> {v.label}
            //         </div>
            //       );
            //     } else if (v.tabella == "e") {
            //       tags.push(
            //         <div className="flexCenter" {...props(i)}>
            //           <ExplicitIcon style={{ marginRight: 10 }} /> {v.label}
            //         </div>
            //       );
            //     }
            //   });

            //   return tags;
            // }}
            options={optionsMontatori}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Montatori"
                className="inputCustom selectMontatori"
              />
            )}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          {/* //luogo */}
          <Autocomplete
            disabled={isDisabilitato}
            filterOptions={(x) => x}
            value={
              optionsLuogo.find((op) => op.value == idLuogo)
                ? optionsLuogo.find((op) => op.value == idLuogo)
                : null
            }
            inputValue={testoSelectLuogo}
            onInputChange={(e, value) => {
              console.log("query luoghi", value);
              setTestoSelectLuogo(value);
              if (value.length >= 3) {
                queryLuoghi(value);
              }
            }}
            onChange={(e, value) => {
              console.log("change autoc", value);
              setIdLuogo(value?.value);
            }}
            options={
              optionsLuogo
              //primi 50 o dopo 3 caratteri valori corrispondenti a ricerca
              // testoSelectLuogo.length < 3
              //   ? optionsLuogo.slice(0, 50)
              //   : optionsLuogo.filter((e) => {
              //       if(e.label.toLowerCase().includes(testoSelectLuogo.toLowerCase())){
              //         return true
              //       }
              //     })
            }
            renderInput={(params) => (
              <TextField {...params} label="Luogo" className="inputCustom" />
            )}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <TextField
            disabled={isDisabilitato}
            required
            label="Lavoro da svolgere"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            className="inputCustom"
            value={lavoro || ""}
            onChange={(e) => {
              setLavoro(e.target.value);
            }}
          />
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            className="inputCustom"
            type="submit"
            variant="contained"
            disabled={isDisabilitato}
          >
            Salva
          </Button>
          {props.fnChiudi != null ? (
            // solo se aperto in popup
            <Button
              color="error"
              className="inputCustom"
              variant="contained"
              onClick={props.fnChiudi}
            >
              Chiudi
            </Button>
          ) : (
            <Button className="inputCustom" href="/attivita">
              Annulla
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;
