import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

const Comp = () => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  let location = useLocation();

  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [attivo, setAttivo] = useState(false);
  const [livelloAdmin, setLivelloAdmin] = useState(null);
  const [azienda, setAzienda] = useState(null);
  const [isDisabilitato, setIsDisabilitato] = useState(false);
  const [utenteInterno, setUtenteInterno] = useState(true);

  const [ruoli, setRuoli] = useState([]);
  const [ruoliUtente, setRuoliUtente] = useState({});
  const [aziende, setAziende] = useState([]);

  const [optionsLivello, setOptionsLivello] = useState([]);
  // const optionsLivello = [
  //   { label: "Utente", value: 0 },
  //   { label: "Admin Azienda", value: 1 },
  //   { label: "Admin Globale", value: 2 },
  // ];

  async function queryUtente() {
    const res = await monFetch("/userGet", {
      id: params.utente,
      table: params.tabella,
    });

    console.log(res);

    const user = res.user;
    setNome(user.first_name);
    setCognome(user.last_name);
    setEmail(user.email);
    setAttivo(user.active ? true : false);
    setLivelloAdmin(user.admin_level_id);
    setUtenteInterno(user.username ? true : false);
    setAzienda(user.company_id);

    if (!res.username) {
      queryAziende();
    }
    //ruoli utente
    const ruoliTemp = {};
    user.roles.forEach((el) => {
      ruoliTemp[el.role_id] = true;
    });
    setRuoliUtente(ruoliTemp);
  }

  async function queryRuoli() {
    const res = await monFetch("/roleList", {});
    console.log(res);
    setRuoli(res.role);
  }

  async function queryLivelliAdmin() {
    const res = await monFetch("/adminLevelList", {});
    console.log(res);
    const livelliAdmin = [];
    res.level.forEach((el) => {
      livelliAdmin.push({ label: el.level, value: el.id });
    });
    console.log(livelliAdmin);
    setOptionsLivello(livelliAdmin);
  }

  async function queryAziende() {
    const res = await monFetch("/companyList", {});
    console.log(res);
    let aziende = [];
    res.company.forEach((el) => {
      aziende.push({ label: el.name, id: el.id });
    });
    console.log(aziende);
    setAziende(aziende);
  }

  async function salvaUtente(e) {
    e.preventDefault();

    //array di id ruoli da passare
    let ruoliUtenteSalva = [];
    Object.entries(ruoliUtente).forEach((k) => {
      if (k[1] == true) {
        ruoliUtenteSalva.push(k[0]);
      }
    });

    if (livelloAdmin > 1) {
      ruoliUtenteSalva = [];
    }

    // console.log(password);
    // return

    const data = {
      id: params.utente,
      table: params.tabella,
      email: email,
      first_name: nome,
      last_name: cognome,
      password: password,
      active: attivo ? 1 : 0,
      roles: ruoliUtenteSalva,
      admin_level: livelloAdmin,
      company_id: azienda,
    };

    if (livelloAdmin == 2) {
      delete data.company_id;
    }
    const res = await monFetch("/userUpdate", data);
    console.log(res);
    // return
    if (res.success) {
      window.location.reload();
    }
  }

  useEffect(() => {
    queryRuoli();

    if (Cookies.get("adminLevel") <= 1) {
      setIsDisabilitato(true);
    }

    queryLivelliAdmin();
    queryUtente();
  }, []);

  return (
    <form onSubmit={(e) => salvaUtente(e)}>
      <h2>Modifica Utente</h2>
      <TextField
        label="Email"
        disabled={utenteInterno}
        required={utenteInterno}
        fullWidth
        type="email"
        variant="outlined"
        className="inputCustom"
        value={email || ""}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />

      <TextField
        label="Nome"
        disabled={utenteInterno}
        required
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={nome || ""}
        onChange={(e) => {
          setNome(e.target.value);
        }}
      />
      <TextField
        label="Cognome"
        disabled={utenteInterno}
        required
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={cognome || ""}
        onChange={(e) => {
          setCognome(e.target.value);
        }}
      />

      {!utenteInterno && (
        <TextField
          label="Password"
          fullWidth
          //type="password"
          variant="outlined"
          className="inputCustom"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      )}
      <div className="valign inputCustom">
        <span>Attivo: </span>
        <Checkbox
          disabled={isDisabilitato}
          checked={attivo}
          onChange={(e) => setAttivo(e.target.checked)}
        />
      </div>

      <h4>Livello Admin</h4>
      <Autocomplete
        disabled={isDisabilitato}
        value={
          optionsLivello.length > 0 && livelloAdmin
            ? optionsLivello.find((op) => op.value == livelloAdmin)
            : null
        }
        onChange={(e, value) => {
          console.log("change autoc", value);
          if (!value) {
            value = null;
          } else {
            value = value.value;
          }
          setLivelloAdmin(value);
        }}
        options={optionsLivello}
        renderInput={(params) => <TextField {...params} label="Livello" />}
      />
      {/* {Cookies.get("adminLevel") == 2 && livelloAdmin < 2 && ( */}
      <>
        <h4>Ditta</h4>
        <Autocomplete
          value={aziende.find((op) => op.id == azienda) || null}
          onChange={(e, value) => {
            console.log("change autoc", aziende, value);
            if (!value) {
              return;
            }
            setAzienda(value.id);
          }}
          options={aziende}
          renderInput={(params) => <TextField {...params} label="Ditta" />}
        />
      </>
      {/* )} */}
      {livelloAdmin != null && livelloAdmin == 1 && (
        <>
          <h4>Ruoli</h4>
          {ruoli.map((ruolo) => {
            return (
              <div
                key={ruolo.id}
                className="valign"
                style={{ display: "inline-block" }}
              >
                <Checkbox
                  disabled={isDisabilitato}
                  checked={ruoliUtente[ruolo.id] || false}
                  onChange={(e) => {
                    const ruoliUtente2 = { ...ruoliUtente };
                    ruoliUtente2[ruolo.id] = e.target.checked;
                    setRuoliUtente(ruoliUtente2);
                  }}
                />
                <span>{ruolo.name}</span>
              </div>
            );
          })}
        </>
      )}
      <br />
      <br />
      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {Cookies.get("adminLevel") > 1 ? (
            <Button type="submit" variant="contained">
              Salva
            </Button>
          ) : (
            <span></span>
          )}

          <Button href="/utenti">Annulla</Button>
        </div>
      </Grid>
    </form>
  );
};

export default Comp;
