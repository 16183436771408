// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// // import LibriAggiorna from "./LibriAggiorna";
// // import PrenotaLibro from "./PrenotaLibro";
// import Button from "@mui/material/Button";
// import { Link } from "react-router-dom";
// import TextField from "@mui/material/TextField";
import { monFetch } from "../inc/inc";
// import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import ReactDOM from "react-dom";
import HomeIcon from "@mui/icons-material/Home";
import ExplicitIcon from "@mui/icons-material/Explicit";
import { useLocation } from "react-router-dom";
// import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import AttivitaNuovoAggiorna from "./AttivitaNuovoAggiorna";

import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import itLocale from "@fullcalendar/core/locales/it";
import listPlugin from "@fullcalendar/list";

import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // optional for styling

const Comp = () => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [montatori, setMontatori] = useState([]);
  const [aggiornaEvento, setAggiornaEvento] = useState(false);
  const [idEvento, setIdEvento] = useState(null);

  async function query(data) {
    const res = await monFetch("/taskList", data);
    console.log(res);
    const task = res.tasks;
    const temp = [];

    const arrayRuoli = JSON.parse(Cookies.get("roles"));
    const adminLevel = Cookies.get("adminLevel");

    task.forEach((el) => {
      let idMontatori = el.montatori_ids?.split(",");
      let idMontatoriExt = el.montatori_ids_external?.split(",");
      //aggiungo capocommessa all'array dei montatori
      idMontatori.push(el.capo_commessa_id);
      let datePlus1Day = new Date(el.date_end);

      // data + 1 giorno, se no il giorno finale non viene compreso nella visualizzazione
      datePlus1Day.setDate(datePlus1Day.getDate() + 1);
      //giorno sempre con 0 iniziale
      let giorno =
        datePlus1Day.getDate() > 9
          ? datePlus1Day.getDate()
          : "0" + datePlus1Day.getDate();

      let mese =
        datePlus1Day.getMonth() + 1 > 9
          ? datePlus1Day.getMonth() + 1
          : "0" + (datePlus1Day.getMonth() + 1);

      datePlus1Day = datePlus1Day.getFullYear() + "-" + mese + "-" + giorno;
      //

      console.log(el.date_start, datePlus1Day);

      idMontatori.forEach((idMont, index) => {
        if (
          adminLevel == 1 &&
          !arrayRuoli.includes(2) &&
          !arrayRuoli.includes(11)
        ) {
          if (idMont != Cookies.get("id")) {
            return;
          }
        }

        temp.push({
          id: el.id,
          start: el.date_start,
          end: datePlus1Day,
          title: (el.city || '') + " - " +(el.address || ''),
          resourceId: idMont,
          backgroundColor: el.color,
          borderColor: el.color,
          extendedProps: {
            luogo: (el.company || '???')  + " - " + (el.city || '') + " " +(el.address || '') +
            " - " + (el.task || '')
            ,
          },
        });
      });

      idMontatoriExt?.forEach((idMont, index) => {
        if (
          adminLevel == 1 &&
          !arrayRuoli.includes(2) &&
          !arrayRuoli.includes(11)
        ) {
          if (idMont != Cookies.get("id")) {
            return;
          }
        }

        temp.push({
          id: el.id,
          start: el.date_start,
          end: datePlus1Day,
          title: (el.city || '')+ " - " + (el.address || ''),
          resourceId: idMont,
          backgroundColor: el.color,
          borderColor: el.color,
          extendedProps: {
            luogo: (el.company || '???')  + " - " + (el.city || '') + " " +(el.address || '') +
            " - " + (el.task || '')
          },
        });
      });
    });
    console.log(temp);
    // return;
    return temp;
  }

  async function queryMontatoriCalendar() {
    const res = await monFetch("/montatoriGetCalendar", {});
    console.log(res);
    const temp = [];
    res.montatori.forEach((el) => {
      temp.push({
        id: el.id,
        title:
          el.first_name +
          " " +
          el.last_name,
          //  + (el.companyName ? " - " + el.companyName : ""),
        last_name: el.last_name,
        company: el.companyName,
      });
    });
    setMontatori(temp);
  }
  // async function eliminaTask(id) {
  //   const res = await monFetch("/taskDelete", { id });
  //   console.log(res);
  //   setPopupElimina(false);
  //   query();
  // }

  useEffect(() => {
    const arrayRuoli = JSON.parse(Cookies.get("roles"));
    const adminLevel = Cookies.get("adminLevel");

    let initialView = "resourceTimeline";
    let center = "";

    if (
      adminLevel == 1 &&
      !arrayRuoli.includes(2) &&
      !arrayRuoli.includes(11)
    ) {
      //se solo montatore
      initialView = "dayGridMonth";
      center = "listMonth dayGridMonth";
    }

    const calendarEl = document.getElementById("calendario");

    if (montatori.length == 0) {
      return;
    }
    console.log(222, montatori);

    //funziona calendario ----------------------------
    let calendar = new Calendar(calendarEl, {
      plugins: [resourceTimelinePlugin, dayGridPlugin, listPlugin],
      initialView: initialView,
      schedulerLicenseKey: "0695492176-fcs-1664434904",
      locale: itLocale,
      resourceAreaColumns: [
        {
          field: "title",
          headerContent: "Montatori",
        },
      ],
      resourceOrder: "company",
      resources: montatori,
      resourceGroupField: "company",

      events: function (fetchInfo, successCallback, failureCallback) {
        query(fetchInfo).then((res) => {
          //nella res ci sono gli eventi del calendario
          successCallback(res);
          //css cliccabile agli eventi della timeline
          if (
            Cookies.get("adminLevel") > 1 ||
            (Cookies.get("adminLevel") < 2 &&
              (arrayRuoli.includes(2) || arrayRuoli.includes(11)))
          ) {
            document.querySelectorAll(".fc-timeline-event").forEach((e) => {
              e.classList.add("cliccabile");
            });
          }
        });
        //
      },
      eventClick: function (eventClickInfo) {
        if (
          Cookies.get("adminLevel") < 2 &&
          !arrayRuoli.includes(2) &&
          !arrayRuoli.includes(11)
        ) {
          //se montatore
          return;
        }
        let idEv = eventClickInfo.event.id;
        setAggiornaEvento(true);
        setIdEvento(idEv);
      },
      eventDidMount: function (e) {
        var el = e.el.getElementsByClassName("fc-list-event-title")[0];
        //mostro luogo in visualizzazione lista
        if (el) {
          el.innerHTML =
            el.innerHTML + "<div>" + e.event.extendedProps.luogo + "</div>";
        }
        //tooltip su visualizzazione calendario montatore
        let el2 = e.el;
        tippy(el2, {
          content: e.event.extendedProps.luogo,
        });
      },
      duration: { days: 10 },
      slotDuration: { days: 1 },
      headerToolbar: {
        //   left: "prev,next today",
        center: center,
        end: "today prev,next",
      },
    });
    calendar.render();
    calendar.today();
  }, [montatori]);

  useEffect(() => {
    //console.log(JSON.parse(Cookies.get("roles")))
    queryMontatoriCalendar();
  }, []);

  return (
    <>
      <div id="calendario"></div>

      <Dialog
        open={aggiornaEvento}
        maxWidth="xl"
        onClose={() => setAggiornaEvento(false)}
      >
        <div style={{ padding: "20px 30px" }}>
          <AttivitaNuovoAggiorna
            id={idEvento}
            fnChiudi={() => setAggiornaEvento(false)}
          />
        </div>
      </Dialog>
    </>
  );
};

export default Comp;
